import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 3; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/11-dec-23-2/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function PrizeDistributionCeremony2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Prize Distribution Ceremony</h1>

        <p>
          A Prize Distribution Ceremony was held recently to honour and applaud
          the young achievers of Chinmaya Vidyalaya.
          <br />
          <br />
          The school’s culture of encouraging sports and co-scholastic
          activities results in stupendous achievements in the interschool
          achievements.
          <br />
          <br />
          A special recognition was given to Prachi Tokas, a class X student,
          who won the Silver Medal at the IBA Junior World Boxing Championship.
          <br />
          <br />
          Chinmaya Vidyalaya is exceedingly proud of each of its champions who
          have brought laurels to the school.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
